<template>
  <div
    class="bg-white px-8 contract-detail-page"
    style="height: calc(100vh - 50px)"
    :key="uniqueId"
  >
    <v-row v-if="pageLoaded">
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="6" class="d-flex align-center justify-start">
            <v-chip
              label
              outlined
              color="blue darken-4"
              class="font-weight-600"
              >{{ dbContract.barcode }}</v-chip
            >
            <v-chip
              label
              :color="dbContract?.status_relation?.color"
              :text-color="dbContract?.status_relation?.textcolor"
              class="ml-2 text-uppercase font-weight-600"
              >{{ dbContract?.status_relation?.text }}</v-chip
            >
            <v-chip
              v-if="dbContract?.invoice_converted == 1"
              label
              color="green"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Invoice</v-chip
            >
            <v-chip
              v-if="dbContract?.visit_converted == 1"
              label
              color="orange"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Visit</v-chip
            >
            <v-chip
              label
              v-if="maintenanceType?.value"
              :color="maintenanceType?.color"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >{{ maintenanceType?.text }}</v-chip
            >
            <template v-if="dbContract?.has_tool == 1">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="green darken-4"
                    class="ml-2"
                    >mdi-tools</v-icon
                  >
                </template>
                <span>Tools</span>
              </v-tooltip>
            </template>
          </v-col>
          <v-col md="6" class="d-flex align-center justify-end">
            <template
              v-if="
                dbContract?.schedule_generated == 1 && dbContract?.status != 4
              "
            >
              <v-btn
                v-if="maintenanceType?.value != 'onetime'"
                depressed
                small
                v-on:click="scheduleDialog = true"
                color="orange darken-4 white--text"
                class="custom-bold-button"
                ><v-icon small left>mdi-timer-outline</v-icon> Show
                Schedule</v-btn
              >
              <v-btn
                depressed
                small
                v-on:click="downloadPDF()"
                color="cyan white--text"
                class="custom-bold-button mx-4"
                ><v-icon small left>mdi-file-pdf</v-icon> Download PDF</v-btn
              >
            </template>
            <v-btn
              v-if="dbContract?.status == 5"
              v-on:click="updateMoreAction('mark_as_cancelled')"
              depressed
              color="cyan white--text"
              class="custom-bold-button mx-1"
              ><v-icon small left>mdi-cancel</v-icon>Mark as Cancel
            </v-btn>
            <v-btn
              v-if="
                dbContract?.schedule_generated == 0 && dbContract?.status != 4
              "
              v-on:click="createScheduleDialog = true"
              depressed
              color="cyan white--text"
              class="custom-bold-button mx-4"
              ><v-icon small left>mdi-timer-outline</v-icon>Generate
              Schedule</v-btn
            >

            <v-btn
              v-if="false && dbContract?.status == 1 && dbContract?.status != 4"
              v-on:click="updateMoreAction('edit')"
              depressed
              color="cyan white--text"
              class="custom-bold-button"
              ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
            >
            <template
              v-if="
                false &&
                dbContract?.more_actions &&
                dbContract?.more_actions?.length
              "
            >
              <template v-if="dbContract?.more_actions?.length > 3">
                <v-menu
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="cyan white--text"
                      class="custom-bold-button mx-2"
                      >More...
                      <v-icon small right>mdi-chevron-down</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(more, index) in dbContract?.more_actions">
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                        :disabled="more.disabled"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <v-btn
                  v-for="(more, index) in dbContract?.more_actions"
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button mx-2"
                  ><v-icon small left>{{ more.icon }}</v-icon>
                  {{ more.title }}</v-btn
                >
              </template>
            </template>
            <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
              ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5">
        <v-row>
          <v-col md="12">
            <span
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
            >
              <router-link
                :to="
                  getDefaultRoute('customer.detail', {
                    params: {
                      id: dbContract?.customer_relation?.id,
                    },
                  })
                "
                >{{ dbContract?.customer_relation?.display_name }}
              </router-link>
            </span>
          </v-col>
          <v-col md="6">
            <label class="my-0">Billing Location</label>
            <template v-if="dbContract?.billing_relation?.id">
              <p class="mb-0">
                {{ dbContract?.billing_relation?.property_address }}
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbContract?.billing_contact_person_relation?.id">
              <p class="mb-0">
                {{ dbContract?.billing_contact_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{ dbContract?.billing_contact_person_relation?.primary_phone }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{ dbContract?.billing_contact_person_relation?.primary_email }}
                <v-tooltip
                  top
                  content-class="custom-top-tooltip"
                  v-if="
                    dbContract?.billing_contact_person_relation?.primary_email
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      style="font-size: 20px"
                      :color="
                        dbContract.notify_billing_contact_person ? 'cyan' : ''
                      "
                    >
                      mdi-email
                    </v-icon>
                  </template>
                  <span v-if="dbContract.notify_billing_contact_person"
                    >Notification sent</span
                  >
                  <span v-else>Notification not sent.</span>
                </v-tooltip>
              </p>
            </template>
          </v-col>
          <v-col md="6">
            <label class="my-0">Site Location</label>
            <template v-if="dbContract?.property_relation?.id">
              <p class="mb-0">
                {{ dbContract?.property_relation?.property_address }}
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbContract?.property_contact_person_relation?.id">
              <p class="mb-0">
                {{ dbContract?.property_contact_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{
                  dbContract?.property_contact_person_relation?.primary_phone
                }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{
                  dbContract?.property_contact_person_relation?.primary_email
                }}
                <v-tooltip
                  top
                  content-class="custom-top-tooltip"
                  v-if="
                    dbContract?.billing_contact_person_relation?.primary_email
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      style="font-size: 20px"
                      :color="
                        dbContract.notify_property_contact_person ? 'cyan' : ''
                      "
                    >
                      mdi-email
                    </v-icon>
                  </template>
                  <span v-if="dbContract.notify_property_contact_person"
                    >Notification sent</span
                  >
                  <span v-else>Notification not sent.</span>
                </v-tooltip>
              </p>
            </template>
          </v-col>
        </v-row> </v-col
      ><v-col md="7" class="gray-background">
        <div class="d-flex">
          <table width="80%">
            <tr>
              <td
                colspan="2"
                class="color-custom-blue font-weight-700 font-size-19"
              >
                {{ dbContract?.title }}
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-weight-600" width="20%">Rate opportunity</td>
              <td class="py-0 my-0">
                <v-rating
                  readonly
                  class="my-0 py-0"
                  v-model.trim="dbContract.rating"
                  background-color="orange lighten-3"
                  color="orange"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="font-size-18 font-weight-600">
                Reference #
              </td>
              <td valign="middle" class="font-size-18 font-weight-500">
                <label class="m-0" v-if="dbContract?.customer_ref">{{
                  dbContract.customer_ref
                }}</label>
                <em v-else class="text-muted"> no refernce </em>
              </td>
            </tr>
            <tr v-if="dbContract?.quotation_relation?.id">
              <td valign="middle" class="font-size-18 font-weight-600">
                Quotation #
              </td>
              <td
                valign="middle"
                class="font-size-18 font-weight-500"
                v-on:click="routeToQuotation()"
              >
                <label
                  class="m-0 cursor-pointer"
                  style="color: #24326d !important"
                  >{{ dbContract?.quotation_relation?.barcode }} -
                  {{ dbContract?.quotation_relation?.job_title }}</label
                >
              </td>
            </tr>
            <tr>
              <td valign="top" class="font-size-18 font-weight-600">
                Contract Duration
              </td>
              <td valign="middle" class="font-size-18 font-weight-500">
                <label
                  class="my-0"
                  v-if="dbContract?.contract_start && dbContract?.contract_end"
                  >This Contract will start on
                  {{ formatDate(dbContract.contract_start) }} and finish on
                  {{ formatDate(dbContract.contract_end) }}</label
                >
              </td>
            </tr>
            <tr v-if="dbContract?.schedule_message">
              <td valign="top" class="font-size-18 font-weight-600">
                Schedule
              </td>
              <td valign="middle" class="font-size-18 font-weight-500">
                <label class="m-0">{{
                  dbContract?.schedule_message ?? "-"
                }}</label>
              </td>
            </tr>
          </table>
          <table width="20%" style="border-left: solid 4px #ffffff">
            <tbody>
              <tr>
                <td
                  height="20px"
                  class="font-weight-600"
                  align="right"
                  width="30%"
                >
                  <span
                    style="border-radius: 50px; padding: 0px 10px"
                    class="py-1 my-0 font-size-18 cyan white--text"
                  >
                    {{ dbContract?.visit_count ?? 0 }}
                  </span>
                </td>
                <td
                  height="20px"
                  class="font-weight-600 cyan--text text--darken-1"
                >
                  Total Visits
                </td>
              </tr>
              <tr>
                <td
                  height="20px"
                  class="font-weight-600"
                  align="right"
                  width="30%"
                >
                  <span
                    style="border-radius: 50px; padding: 0px 10px"
                    class="py-1 my-0 font-size-18 green white--text"
                  >
                    {{ dbContract?.completed_count ?? 0 }}
                  </span>
                </td>
                <td height="20px" class="font-weight-600 green--text">
                  Completed Visits
                </td>
              </tr>
              <tr>
                <td
                  height="20px"
                  class="font-weight-600"
                  align="right"
                  width="30%"
                >
                  <span
                    style="border-radius: 50px; padding: 0px 10px"
                    class="py-1 my-0 font-size-18 yellow darken-4 white--text"
                  >
                    {{ dbContract?.pending_count ?? 0 }}
                  </span>
                </td>
                <td
                  height="20px"
                  class="font-weight-600 yellow--text text--darken-4"
                >
                  Pending Visits
                </td>
              </tr>
              <tr>
                <td
                  height="20px"
                  class="font-weight-600"
                  align="right"
                  width="30%"
                >
                  <span
                    style="border-radius: 50px; padding: 0px 10px"
                    class="py-1 my-0 font-size-18 red white--text"
                  >
                    {{ dbContract?.cancelled_count ?? 0 }}
                  </span>
                </td>
                <td height="20px" class="font-weight-600 red--text">
                  Cancelled Visits
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="contractTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-14 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/contract.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab
            v-if="false"
            class="font-size-14 font-weight-600 px-8"
            href="#invoice"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Invoices
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="contractTab" class="custom-border-top">
          <v-tab-item value="overview">
            <v-expansion-panels mandatory accordion>
              <v-expansion-panel v-if="dbContract?.quotation_relation?.id">
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      Quotation
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100 d-flex">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td class="font-weight-600" width="10%">
                            Quotation #
                          </td>
                          <td class="font-weight-600" width="40%">
                            <v-chip
                              label
                              outlined
                              style="max-height: 20px"
                              color="blue darken-4"
                              class="font-weight-600"
                              v-on:click="routeToQuotation()"
                              >{{
                                dbContract?.quotation_relation?.barcode
                              }}</v-chip
                            >
                            <v-chip
                              label
                              style="max-height: 20px"
                              :color="
                                dbContract?.quotation_relation?.status_relation
                                  ?.color
                              "
                              :text-color="
                                dbContract?.quotation_relation?.status_relation
                                  ?.textcolor
                              "
                              class="ml-2 text-uppercase font-weight-600"
                              >{{
                                dbContract?.quotation_relation?.status_relation
                                  ?.text
                              }}</v-chip
                            >
                          </td>
                          <td class="font-weight-600" width="10%">Date</td>
                          <td class="font-weight-600" width="40%">
                            {{
                              formatDate(dbContract?.quotation_relation?.date)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600" width="10%">Sale by</td>
                          <td class="font-weight-600" width="40%">
                            <template
                              v-if="
                                dbContract?.quotation_relation?.sale_by_relation
                                  ?.display_name
                              "
                            >
                              {{
                                dbContract?.quotation_relation?.sale_by_relation
                                  ?.display_name
                              }}</template
                            >
                            <em class="text-muted" v-else> no sale by</em>
                          </td>
                          <td class="font-weight-600" width="10%">
                            Customer ref
                          </td>
                          <td class="font-weight-600" width="40%">
                            <template
                              v-if="dbContract?.quotation_relation?.reference"
                            >
                              {{
                                dbContract?.quotation_relation?.reference
                              }}</template
                            >
                            <em class="text-muted" v-else> no Customer ref</em>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600" width="10%">
                            Quote Amount
                          </td>
                          <td class="font-weight-600" width="40%">
                            {{
                              formatMoney(dbContract?.quotation_relation?.total)
                            }}
                          </td>

                          <td class="font-weight-600" width="10%">Your ref</td>
                          <td class="font-weight-600" width="40%">
                            <template
                              v-if="
                                dbContract?.quotation_relation?.your_reference
                              "
                            >
                              {{
                                dbContract?.quotation_relation?.your_reference
                              }}</template
                            >
                            <em class="text-muted" v-else> no Your ref</em>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600" width="10%">Quote by</td>
                          <td class="font-weight-600" width="40%">
                            <template
                              v-if="
                                dbContract?.quotation_relation
                                  ?.quote_by_relation?.display_name
                              "
                            >
                              {{
                                dbContract?.quotation_relation
                                  ?.quote_by_relation?.display_name
                              }}</template
                            >
                            <em class="text-muted" v-else> no Quote by</em>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Description</td>
                          <td class="font-weight-600" colspan="3">
                            <template
                              v-if="dbContract?.quotation_relation?.description"
                            >
                              {{
                                dbContract?.quotation_relation?.description
                              }}</template
                            >
                            <em class="text-muted" v-else> no Description</em>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      DESCRIPTION
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <div
                      v-if="dbContract?.description"
                      v-html="dbContract?.description"
                    />
                    <div
                      v-else
                      class="text--secondary"
                      style="font-style: italic"
                    >
                      no description
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      TERMS &amp; CONDITIONS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <div
                      v-if="dbContract?.term_conditions"
                      v-html="dbContract?.term_conditions"
                    />
                    <div
                      v-else
                      class="text--secondary"
                      style="font-style: italic"
                    >
                      No terms &amp; conditions
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      INTERNAL NOTES
                      <!-- & ATTACHMENTS -->
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <v-row>
                    <v-col md="6" class="custom-border-right">
                      <label class="font-size-16 font-weight-600"
                        >Internal Note</label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbContract?.admin_note"
                          v-html="dbContract?.admin_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          No Internal Note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="6" class="custom-border-left">
                      <label class="font-size-16 font-weight-600"
                        >Client Note
                        <span class="text--secondary font-weight-500"
                          ><!-- (visible on PDF) --></span
                        ></label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbContract?.client_note"
                          v-html="dbContract?.client_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          No Client Note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="12" class="custom-border-top mt-4" v-if="false">
                      <v-row>
                        <v-col md="6">
                          <label class="font-size-16 font-weight-600"
                            >Attachments ({{
                              dbContract?.attachments?.length
                            }})</label
                          >
                          <table width="100%" class="custom-border-top-1px">
                            <tbody>
                              <tr
                                v-for="(row, index) in dbContract.attachments"
                                :key="`view-attach-${index}`"
                              >
                                <td
                                  valign="middle"
                                  width="80%"
                                  class="pr-2 py-2"
                                >
                                  {{ row.name }}
                                </td>
                                <td
                                  valign="middle"
                                  width="20%"
                                  align="right"
                                  class="pl-2 py-2"
                                >
                                  <v-icon color="cyan" class="mr-2"
                                    >mdi-download</v-icon
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <v-tab-item value="line-item">
            <line-item-view />
          </v-tab-item>
          <v-tab-item v-if="false" value="invoice">
            <internal-invoice type="contract" :type-id="dbContract?.id" />
          </v-tab-item>
          <v-tab-item value="visit">
            <internal-visit
              :customer-id="dbContract?.customer"
              :property-id="dbContract?.property"
              :contract-id="dbContract?.id"
              detailType="tickets"
              visitType="all"
              :visit-status="0"
              detailRoute="visit.detail"
              internal
            />
          </v-tab-item>
          <v-tab-item value="history">
            <internal-history-detail
              type="contract"
              :type_id="dbContract?.id"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
        <v-progress-linear rounded indeterminate height="8" color="orange" />
      </v-col>
    </v-row>
    <confirm-dialog
      key="confirm-dialog-contract"
      :common-dialog="confirmDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="px-10" fluid>
          <p class="font-weight-600 font-size-19">
            Are you sure, you want to
            <span class="font-weight-700">{{ getConfirmTitle() }}</span
            >?
          </p>
          <v-layout
            class="my-4"
            v-if="
              confirmType == 'mark_as_approved' ||
              confirmType == 'mark_as_rejected' ||
              confirmType == 'mark_as_accepted'
            "
          >
            <v-flex>
              <label class="font-weight-500 font-size-16 required"
                >Remark</label
              >
              <v-textarea
                v-model.trim="actionData.remark"
                auto-grow
                dense
                filled
                color="cyan"
                label="Remark"
                solo
                flat
                row-height="25"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout class="mt-4" v-if="confirmType == 'mark_as_accepted'">
            <v-flex md12>
              <label class="font-weight-500 font-size-16"
                >Attachments (Max 5)</label
              >
              <table width="100%">
                <tbody>
                  <tr
                    v-for="(row, index) in actionData.attachments"
                    :key="index"
                  >
                    <td width="50%" class="pl-0 pr-2 py-2">
                      <v-file-input
                        prepend-inner-icon="mdi-attachment mdi-rotate-90"
                        placeholder="Click here to select file"
                        label="Click here to select file"
                        prepend-icon=""
                        color="cyan"
                        solo
                        flat
                        v-model="row.accepted_file"
                        dense
                        v-on:change="updateFileName(index)"
                        hide-details
                      ></v-file-input>
                    </td>
                    <td width="50%" class="pr-0 pl-2 py-2">
                      <v-text-field
                        placeholder="File Name"
                        label="File Name"
                        dense
                        solo
                        flat
                        color="cyan"
                        v-model="row.file_name"
                        hide-details
                        class="has-delete-outer-icon"
                        append-outer-icon="mdi-delete"
                        v-on:click:append-outer="removeRow(index)"
                        :suffix="getFileExtension(row.file_type)"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="actionData?.attachments?.length < 5">
                    <td colspan="2" class="pl-0">
                      <v-btn
                        color="cyan white--text"
                        small
                        v-on:click="addAttachmentRow()"
                        class="custom-bold-button"
                        >Add More...</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirmLoading"
          v-on:click="confirmDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirmSubmit()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </confirm-dialog>
    <send-mail
      :mail-dialog="mailDialog"
      title="Send as Email"
      type="contract"
      v-on:close="mailDialog = false"
      :type-id="contractId"
      v-on:success="getContract()"
    ></send-mail>
    <confirm-dialog
      key="show-schedule-dialog-quotation"
      :common-dialog="scheduleDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title>
        Contract Schedule ({{ contractSchedule.length }})</template
      >
      <template v-slot:body>
        <v-container fluid>
          <table width="100%">
            <tr>
              <th class="text-center">S.No.</th>
              <th>Date</th>
              <th>Day</th>
              <th>Status</th>
            </tr>
            <tr
              v-for="(row, index) in contractSchedule"
              :key="index"
              :class="{ 'visit-row-hover': row.is_edited }"
            >
              <td
                class="font-size-14 font-weight-500"
                align="center"
                valign="middle"
              >
                {{ index + 1 }}.
              </td>
              <td class="font-size-14 font-weight-500">
                {{ formatDate(row.start_date) }}
              </td>
              <td class="font-size-14 font-weight-500">
                {{ row.days_of_week }}
              </td>
              <td>
                <v-icon color="green lighten-1" v-if="row.status == 1"
                  >mdi-check</v-icon
                >
                <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="scheduleDialog = false"
          depressed
          class="custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
      </template>
    </confirm-dialog>
    <create-schedule-dialog
      :quotation-id="quotationId"
      :dialog="createScheduleDialog"
      v-on:close="createScheduleDialog = false"
      v-on:success="getContract()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET } from "@/core/services/store/request.module";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";
import LineItemViewV2 from "@/view/pages/partials/Line-Item-View-V2.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import CreateScheduleDialog from "@/view/pages/quotation/Contract/CreateScheduleDialog";
import {
  CLEAR_CONTRACT_STATE,
  UPDATE_SCHEDULE_STATE,
} from "@/core/services/store/contract.module";
import {
  SET_CUSTOMER,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      contractSchedule: [],
      scheduleDialog: false,
      formLoading: false,
      tab: null,
      createScheduleDialog: false,
      quotationId: 0,
      contractTab: "line-item",
      dbContract: {},
      contractId: 0,
      uniqueId: Number(new Date()),
      pageLoaded: false,
      mailDialog: false,
      confirmDialog: false,
      confirmLoading: false,
      confirmType: null,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    routeToQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: { id: this.dbContract?.quotation_relation?.id },
        })
      );
    },
    confirmSubmit() {
      switch (this.confirmType) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData?.attachments?.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      this.confirmLoading = true;

      this.actionData.status = this.confirmType;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "contract/" + this.contractId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getContract();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData?.attachments?.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData?.attachments?.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editContract();
          break;
        case "revise":
          this.reviseContract();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "mark_as_closed":
          this.confirmDialog = true;
          this.confirmType = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirmDialog = true;
          this.confirmType = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mailDialog = true;
          break;
        case "mark_as_pending":
          this.confirmDialog = true;
          this.confirmType = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirmDialog = true;
          this.confirmType = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirmDialog = true;
          this.confirmType = "mark_as_accepted";
          break;
        case "mark_as_approved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_approved";
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },

    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?type=maintenance&token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?type=maintenance&token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getContract() {
      this.createScheduleDialog = false;
      this.$store
        .dispatch(GET, { url: "contract/" + this.contractId })
        .then(({ data }) => {
          this.contractSchedule = data.contract_schedule;
          delete data.contract_schedule;
          this.dbContract = data;

          this.quotationId = data?.quotation_relation?.id ?? 0;

          if (this.dbContract?.contract_type) {
            if (
              this.dbContract?.contract_start &&
              this.dbContract?.contract_end
            ) {
              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbContractRecurringSchedule.end_mode",
                value: 3,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbContractRecurringSchedule.start_date",
                value: this.dbContract?.contract_start,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbDefaultRecurringSchedule.start_date",
                value: this.dbContract?.contract_start,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbContractRecurringSchedule.end_date",
                value: this.dbContract?.contract_end,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbDefaultRecurringSchedule.end_date",
                value: this.dbContract?.contract_end,
              });
            }

            const contract_type = this.contractRecurringScheduleTypeList.find(
              (row) => row.value == this.dbContract.contract_type
            );

            if (contract_type?.value) {
              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbContractRecurringSchedule.type",
                value: contract_type,
              });

              this.contractRecurringScheduleTypeList.forEach((row, index) => {
                row.disabled = row.value != contract_type.value;
                this.$store.commit(UPDATE_SCHEDULE_STATE, {
                  key: `dbContractRecurringScheduleTypeList.${index}`,
                  value: row,
                });
              });
            }
          }

          this.$store.dispatch(SET_VIEW_CALCULATION, {
            additional_rows:
              this.dbContract?.quotation_relation?.additional_rows ?? [],
            ctx_line_item_total_label:
              this.dbContract?.ctx_line_item_total_label ?? "Total",
            ctx_total_label:
              this.dbContract?.ctx_total_label ?? "Total Amount (SGD)",
            ctx_sub_total_label:
              this.dbContract?.ctx_sub_total_label ?? "Sub Total",
            ctx_discount_label_one:
              this.dbContract?.ctx_discount_label_one ?? "Discount",
            ctx_discount_label: this.dbContract?.ctx_discount_label ?? null,
            ctx_discount_value: this.dbContract?.ctx_discount_value ?? 0,
            ctx_discount: this.dbContract?.ctx_discount ?? 0,
            ctx_discount_type: this.dbContract?.ctx_discount_type ?? 1,
            sub_total: this.dbContract?.sub_total ?? 1,
            discount_type: this.dbContract?.discount_type ?? 1,
            discount_value: this.dbContract?.discount_value ?? 0,
            discount_amount: this.dbContract?.discount_amount ?? 0,
            tax_active: this.dbContract?.tax_active ?? false,
            tax_value: this.dbContract?.tax_value ?? 0,
            tax_amount: this.dbContract?.tax_amount ?? 0,
            adjustment: this.dbContract?.adjustment ?? 0,
            total: this.dbContract?.total ?? 0,
            show_price: true,
            discount_level: this.dbContract?.discount_level ?? "transaction",
          });

          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item ?? []);

          this.$store.commit(SET_CUSTOMER, this.dbContract?.customer_relation);

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Contract", route: "contract" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
          this.uniqueId = Number(new Date());
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.contractId = this.lodash.toSafeInteger(this.$route.params.id);
    this.contractTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getContract();
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
  },

  beforeDestroy() {
    this.$store.commit(CLEAR_CONTRACT_STATE);
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  computed: {
    ...mapGetters(["contractRecurringScheduleTypeList"]),
    maintenanceType() {
      return this.contractRecurringScheduleTypeList.find(
        (row) => row.value == this.dbContract?.contract_type
      );
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "create-schedule-dialog": CreateScheduleDialog,
    "line-item-view": LineItemViewV2,
    "internal-history-detail": InternalHistoryDetail,
    "internal-invoice": InternalInvoice,
    "internal-visit": InternalVisit,
    "confirm-dialog": Dialog,
    "send-mail": SendMail,
  },
};
</script>
<style lang="scss" scoped>
.contract-detail-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>
