<template>
  <v-row class="mx-0">
    <v-col md="12" class="py-0">
      <v-row>
        <v-col md="12" class="py-0">
          <v-row class="custom-grey-border">
            <v-col
              v-if="false"
              md="12"
              class="light-gray-background custom-border-bottom d-flex justify-space-between"
            >
              <div
                style="font-size: 19px"
                class="text-uppercase font-weight-700 color-custom-blue"
              >
                Schedule
              </div>
            </v-col>
            <v-col md="12" class="custom-border-bottom light-gray-background">
              <v-btn
                v-for="(row, index) in contractRecurringScheduleTypeList"
                :key="`recurring-schedule-type-list-${index}`"
                :disabled="row.disabled || loadingDialog"
                :loading="loadingDialog"
                :color="row.color"
                depressed
                :class="{
                  'btn-selected': !(
                    contractRecurringSchedule?.type?.value == row.value
                  ),
                  '': row.border,
                }"
                :text="!(contractRecurringSchedule?.type?.value == row.value)"
                v-on:click="updateWeekDays(row)"
                class="mr-4 custom-bold-button"
              >
                {{ row.text }}
              </v-btn>
            </v-col>
            <v-col md="12">
              <label class="ml-2 font-weight-600" for="contract-is_town_council"
                >Is town council ?</label
              >
              <RadioInputCustom
                v-on:change="
                  updateScheduleState(
                    'dbContractRecurringSchedule.is_town_council',
                    contractRecurringSchedule.is_town_council
                  )
                "
                v-model="contractRecurringSchedule.is_town_council"
                row
                :disabled="vCustomer.is_town_council == 1 ? true : false"
                color="red darken-4"
                :items="[
                  {
                    label: 'Yes',
                    value: 1,
                  },
                  {
                    label: 'No',
                    value: 0,
                  },
                ]"
              ></RadioInputCustom>
            </v-col>
            <v-col md="4">
              <label
                class="ml-2 font-weight-600"
                for="contract-one-off-start-date"
                >Start Date</label
              >
              <!-- :min-date="todayDate" -->
              <DatePicker
                v-on:change="
                  updateScheduleState(
                    'dbContractRecurringSchedule.start_date',
                    $event
                  );
                  updateEndDate(false);
                "
                :value="contractRecurringSchedule.start_date"
                key="contract-one-off-start-date"
                id="contract-one-off-start-date"
                placeholder="Start Date"
              />
            </v-col>

            <v-col
              md="12"
              v-if="contractRecurringSchedule?.type?.group == 'monthly'"
            >
              <div class="mt-2">
                <v-radio-group
                  :disabled="loadingDialog"
                  class="mt-0"
                  hide-details
                  mandatory
                  :value="contractRecurringSchedule.recurring_pattern"
                  v-on:change="
                    updateScheduleState(
                      'dbContractRecurringSchedule.recurring_pattern',
                      $event
                    )
                  "
                >
                  <v-radio
                    :disabled="loadingDialog"
                    :value="1"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'disabled-rw pointer-events-none':
                            contractRecurringSchedule.recurring_pattern != 1,
                        }"
                        class="d-flex align-center font-weight-500"
                      >
                        <label class="text-center my-0 ml-2"
                          >Selection by Date</label
                        >
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    :disabled="loadingDialog"
                    :value="2"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'disabled-rw pointer-events-none':
                            contractRecurringSchedule.recurring_pattern != 2,
                        }"
                        class="d-flex align-center font-weight-500"
                      >
                        <div style="width: 150px">
                          <v-select
                            dense
                            filled
                            :disabled="loadingDialog"
                            item-color="cyan"
                            :items="contractDurationList"
                            item-text="long_name"
                            item-value="id"
                            placeholder="Select Day"
                            v-model="contractRecurringSchedule.week_day"
                            v-on:change="updateSchedule()"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          />
                        </div>
                        <div style="width: 150px">
                          <v-select
                            dense
                            filled
                            :disabled="loadingDialog"
                            item-color="cyan"
                            item-text="long_name"
                            item-value="id"
                            :items="contractWeekDays"
                            placeholder="Select Week Day"
                            v-model="contractRecurringSchedule.week_count"
                            v-on:change="updateSchedule()"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          />
                        </div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col v-if="false" md="4">
              <label
                class="ml-2 font-weight-600"
                for="contract-one-off-start-time"
                >Start Time</label
              >
              <TimePicker
                v-on:change="
                  updateScheduleState(
                    'dbContractRecurringSchedule.start_time',
                    $event
                  )
                "
                :value="contractRecurringSchedule.start_time"
                key="contract-one-off-start-time"
                id="contract-one-off-start-time"
                placeholder="Start Time"
              />
            </v-col>
            <v-col v-if="false" md="4">
              <label
                class="ml-2 font-weight-600"
                for="contract-one-off-end-time"
                >End Time</label
              >
              <TimePicker
                v-on:change="
                  updateScheduleState(
                    'dbContractRecurringSchedule.end_time',
                    $event
                  )
                "
                :value="contractRecurringSchedule.end_time"
                key="contract-one-off-end-time"
                id="contract-one-off-end-time"
                placeholder="End Time"
              />
            </v-col>
            <v-col
              md="4"
              v-if="contractRecurringSchedule?.type?.group == 'onetime'"
            >
              <label
                class="ml-2 font-weight-600"
                for="contract-one-off-end-date"
                >End Date</label
              >
              <DatePicker
                v-on:change="
                  updateScheduleState(
                    'dbContractRecurringSchedule.end_date',
                    $event
                  )
                "
                :value="contractRecurringSchedule.end_date"
                key="contract-one-off-end-date"
                id="contract-one-off-end-date"
                placeholder="End Date"
              />
              <!-- :min-date="contractRecurringSchedule.start_date" -->
            </v-col>
            <v-col md="12" v-else>
              <div class="mt-2">
                <v-radio-group
                  class="mt-0"
                  hide-details
                  mandatory
                  :value="contractRecurringSchedule.end_mode"
                  v-on:change="
                    updateScheduleState(
                      'dbContractRecurringSchedule.end_mode',
                      $event
                    )
                  "
                >
                  <v-radio :value="2" hide-details color="cyan">
                    <template v-slot:label>
                      <div
                        class="d-flex align-center"
                        :class="{
                          'pointer-events-none':
                            contractRecurringSchedule.end_mode != 2,
                        }"
                      >
                        <div>End after</div>
                        <div class="mx-2">
                          <v-text-field
                            dense
                            :disabled="
                              loadingDialog ||
                              contractRecurringSchedule.end_mode != 2
                            "
                            filled
                            :value="
                              contractRecurringSchedule.end_mode == 2
                                ? contractRecurringSchedule.occurrence
                                : ''
                            "
                            type="number"
                            placeholder="Enter occurrences"
                            v-on:change="
                              updateScheduleState(
                                'dbContractRecurringSchedule.occurrence',
                                $event
                              )
                            "
                            solo
                            flat
                            color="cyan"
                          />
                        </div>
                        <div>occurrences</div>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="3" hide-details color="cyan">
                    <template v-slot:label>
                      <div
                        class="d-flex align-center"
                        :class="{
                          'pointer-events-none':
                            contractRecurringSchedule.end_mode != 3,
                        }"
                      >
                        <div class="mr-2">End before</div>
                        <DatePicker
                          :disabled="
                            loadingDialog ||
                            contractRecurringSchedule.end_mode != 3
                          "
                          :value="
                            contractRecurringSchedule.end_mode == 3
                              ? contractRecurringSchedule.end_date
                              : ''
                          "
                          v-on:change="
                            updateScheduleState(
                              'dbContractRecurringSchedule.end_date',
                              $event
                            )
                          "
                          :min-date="contractRecurringSchedule.start_date"
                          key="contract-recurring-end-date"
                          placeholder="Select End Date"
                        />
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col md="12">
              <table width="100%">
                <tr>
                  <td
                    height="35px"
                    class="m-0 px-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                  >
                    {{ contractRecurringSchedule.message }}
                  </td>
                  <td
                    v-if="contractRecurringSchedule?.type?.group != 'onetime'"
                    width="150px"
                    height="35px"
                    class="px-0 py-0"
                  >
                    <v-btn
                      v-if="contractScheduleOutput.length"
                      :disabled="loadingDialog"
                      :loading="loadingDialog"
                      v-on:click="openDialog()"
                      style="min-height: 36.5px; width: 100%"
                      color="cyan white--text"
                      depressed
                      class="custom-bold-button"
                    >
                      Show Schedule
                    </v-btn>
                    <v-btn
                      v-else
                      :disabled="loadingDialog"
                      :loading="loadingDialog"
                      v-on:click="showSchedule()"
                      style="min-height: 36.5px; width: 100%"
                      color="cyan white--text"
                      depressed
                      class="custom-bold-button"
                    >
                      Get Schedule
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <ShowSchedule
        :dialog="scheduleDialog"
        :key="`job-show-schedule-${uniqueId}`"
        v-on:close="scheduleDialog = false"
        v-on:next="nextStepSchedule()"
      />
      <ConfirmSchedule
        :dialog="scheduleConfirmDialog"
        :key="`job-confirm-schedule-${uniqueCId}`"
        v-on:close="scheduleConfirmDialog = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ShowSchedule from "@/view/pages/quotation/Contract/ShowSchedule.vue";
import ConfirmSchedule from "@/view/pages/quotation/Contract/ConfirmSchedule.vue";
import RadioInputCustom from "@/view/components/app-component/RadioInputCustom.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import DatePicker from "@/view/components/QDatePicker.vue";
import {
  GET_RECURRING_DATE,
  UPDATE_SCHEDULE_STATE,
} from "@/core/services/store/contract.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "contract-ctx-recurring-schedule",
  data() {
    return {
      scheduleConfirmDialog: false,
      scheduleDialog: false,
      loadingDialog: false,
      uniqueId: Number(new Date()),
      uniqueCId: Number(new Date()),
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      scheduleKey: Number(new Date()),
      timeOut: null,
      dialogTimeOut: null,
    };
  },
  watch: {
    vCustomer: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.contractRecurringSchedule.is_town_council =
            this.vCustomer?.is_town_council;
        }
      },
    },
  },
  methods: {
    updateSelection(dayId) {
      const isSelected = this.contractRecurringSchedule.weeks.includes(dayId);
      if (isSelected) {
        this.contractRecurringSchedule.weeks =
          this.contractRecurringSchedule.weeks.filter(
            (selectedDay) => selectedDay !== dayId
          );
      } else {
        if (
          this.contractRecurringSchedule.weeks.length < this.allowedSelections()
        ) {
          this.contractRecurringSchedule.weeks.push(dayId);
        }
      }
    },
    allowedSelections() {
      const selectedFrequencyData = this.contractRecurringScheduleTypeList.find(
        (item) => item.value === this.contractRecurringSchedule?.type?.value
      );
      return selectedFrequencyData ? selectedFrequencyData?.max_allowed : 0;
    },
    isDisabled(dayId) {
      return (
        this.allowedSelections() ===
          this.contractRecurringSchedule.weeks.length &&
        !this.contractRecurringSchedule.weeks.includes(dayId)
      );
    },
    nextStepSchedule() {
      this.scheduleDialog = false;
      this.$nextTick(() => {
        this.uniqueCId = Number(new Date());
        this.scheduleConfirmDialog = true;
      });
    },
    showSchedule() {
      this.$store.dispatch(GET_RECURRING_DATE);
      this.uniqueId = Number(new Date());
    },
    openDialog() {
      if (this.contractScheduleOutput.length) {
        this.loadingDialog = true;
        this.$store.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbContractScheduleOutput",
          value: this.contractRawScheduleOutput,
        });
        clearTimeout(this.dialogTimeOut);
        this.dialogTimeOut = setTimeout(() => {
          this.scheduleDialog = true;
          this.loadingDialog = false;
          this.scheduleConfirmDialog = false;
        }, 200);
      }
    },
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    updateWeekDays(param) {
      this.updateScheduleState("dbContractRecurringSchedule.message", null);
      this.updateScheduleState("dbContractRecurringSchedule.weeks", []);
      this.updateScheduleState("dbContractRecurringSchedule.type", param);
      this.updateEndDate(true);
    },
    updateEndDate(force) {
      let startDateTime = moment(
        `${this.contractRecurringSchedule.start_date} ${this.contractRecurringSchedule.start_time}`
      );
      let endDateTime = moment(
        `${this.contractRecurringSchedule.end_date} ${this.contractRecurringSchedule.end_time}`
      );

      if (force === true || endDateTime.isSameOrBefore(startDateTime)) {
        let endDate = null;

        if (this.contractRecurringSchedule?.occurrence == 1) {
          endDate = startDateTime.format("YYYY-MM-DD");
        } else {
          switch (this.contractRecurringSchedule?.type?.value) {
            case "onetime":
              endDate = startDateTime.add(1, "day").format("YYYY-MM-DD");
              break;
            case "daily":
              endDate = startDateTime.add(1, "day").format("YYYY-MM-DD");
              break;
            case "weekly":
              endDate = startDateTime.add(7, "day").format("YYYY-MM-DD");
              break;
            case "twice_a_week":
              endDate = startDateTime.add(3, "day").format("YYYY-MM-DD");
              break;
            case "fort_nightly":
              endDate = startDateTime.add(14, "day").format("YYYY-MM-DD");
              break;
            case "thrice_a_week":
              endDate = startDateTime.add(2, "day").format("YYYY-MM-DD");
              break;
            case "monthly":
              endDate = startDateTime.add(1, "month").format("YYYY-MM-DD");
              break;
            case "twice_a_month":
              endDate = startDateTime.add(2, "week").format("YYYY-MM-DD");
              break;
            case "bi_monthly":
              endDate = startDateTime.add(2, "month").format("YYYY-MM-DD");
              break;
            case "quarterly":
              endDate = startDateTime.add(3, "month").format("YYYY-MM-DD");
              break;
            case "bi_annually":
              endDate = startDateTime.add(6, "month").format("YYYY-MM-DD");
              break;
            case "bi_annually":
              endDate = startDateTime.add(1, "year").format("YYYY-MM-DD");
              break;
          }
        }

        this.updateScheduleState(
          "dbContractRecurringSchedule.end_date",
          endDate
        );
      }
    },
    updateSchedule() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.showSchedule();
      }, 200);
    },
    updateScheduleState(key, value) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
      this.updateSchedule();
    },
  },
  components: {
    ShowSchedule,
    ConfirmSchedule,
    RadioInputCustom,
    TimePicker,
    DatePicker,
  },
  mounted() {
    this.updateSchedule();
    let key = "dbContractRecurringSchedule.message";
    this.$store.commit(UPDATE_SCHEDULE_STATE, {
      key,
      value: this.vCustomer?.is_town_council,
    });
  },
  computed: {
    ...mapGetters([
      "contractRecurringScheduleTypeList",
      "contractWeekDays",
      "contractDurationList",
      "contractRecurringSchedule",
      "contractScheduleOutput",
      "contractRawScheduleOutput",
      "vCustomer",
    ]),
  },
};
</script>
